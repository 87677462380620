html, body {
  margin:0;
  /*height:100%;*/
  width: 100%;
}

.App {
  height: calc(100vh - 128px);
  height: -webkit-calc(100vh - 128px);
  height: -moz-calc(100vh - 128px);
  background-image: url("../public/checkerboard.png");
  background-size: 64px;
  /*border: 64px solid transparent;*/
  display: flex;
  flex-direction: column;

  border-image: url('../public/checkerborder.png') 178 round;
  border-width: 64px;
  border-style: solid;
}

.container {
  flex: 1;
  background-color: white;
  overflow: scroll;
}

.menu {
  /*margin: 20px;*/
}

.mom {
  width: 100%;
  height: auto;
}
